<template>
  <div class="flex-y w100 h100">
    <div class="topImg" v-if="$route.meta.topImgShow" v-show="isShow">
       <a :href="advUrl"> <img :src="hengfuObj | imagePath" alt=""></a>
        <!-- <img src="../../src/assets/icon/topImg.png" alt=""> -->
      <div>
        <img src="../assets/icon/delTopImg.png" alt="" @click="delImg()">
      </div>
    </div>
    <Headers></Headers>
    <router-view class="router"></router-view>
    <Footer></Footer>
    <Aside></Aside>
  </div>
</template>

<script>
import Headers from '@/components/common/header.vue';
import Footer from '@/components/common/footer.vue';
import Aside from "@/components/common/aside.vue";
export default {
  name: 'index',
  components: { Headers, Footer, Aside },
  data() {
    return {
      isShow:true,
      hengfuObj:"", //顶部横幅图
      advUrl:"", //顶部横幅图广告链接
    }
  },
  created() {
    this.gethengfu();
  },
  methods: {
    delImg(){
      this.isShow=false;
    },
   gethengfu(){ //获取底部横幅图片
          this.$http.get('pic/adv/config/new/list?code=pc_sy_db1_ggw').then(res=>{
          this.hengfuObj=res.data.data[0].picUrl;
          this.advUrl=res.data.data[0].advUrl;
      })
    },

  },
};
</script>
<style lang="scss" scoped>
.topImg{
    width: 100%;
    height: 80px;
    img{
      display: block;
      width: 100%;
      height: 80px;
    }
    >div{
      width: 1200px;
      height: 36px;
      // background: red;
      margin: 0 auto;
      position:absolute;
      text-align: right;
      left:0;
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img{
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }
.router{
  height: auto;
  width: 100%;
  padding-top: 136px;
}
</style>
